import React from "react"
import LongArticle from "../templates/long-singular"

export default (props) => {
  const { data: {page}, pageContext } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <LongArticle {...props} data={page} />
    </gatsbyi18Context.Provider>
  )
}

export const query = graphql`
  query {
    page: wordpressPage(slug: { eq: "how-to-use-archive" }) {
      type
      id
      title
      slug
      content
      date
      template
      lang: polylang_current_lang
      author {
        name
      }
    }
  }
`
